import React from "react";

import { Container } from "components";

import Tweeter from "components/tweeter";

const Home: React.FC = () => {
  return (
    <Container>
      <Tweeter />
    </Container>
  );
};
/*
       <Header />
      <Main />
      <Cards />
      <Footer />

 */

export default Home;
