import { useEffect, useState, useRef } from "react";
import axios from "axios";

async function fetchTweets() {
  const result = await axios.get("https://bpctwitterbot.structura.dev/tweets");
  return result.data;
}

function TweeterForm({ tweet }) {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [text, setText] = useState(tweet.improvedText);
  const [postResult, setPostResult] = useState(undefined);
  const onSubmit = async (promote?: boolean) => {
    try {
      await axios.post("https://bpctwitterbot.structura.dev/tweet", {
        text,
        media: tweet.media,
        promote,
      });
      console.log("posted!");
      setPostResult("posted!");
    } catch (err: unknown) {
      console.error("Post failed", err);
      setPostResult("Post failed:" + err);
    }
  };
  useEffect(() => {
    changeTextarea();
  }, [textareaRef]);

  const changeTextarea = () => {
    if (!textareaRef.current) return;
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = textareaRef.current.scrollHeight + "px";
  };
  return (
    <>
      <div>
        <div className="">
          <a
            href={tweet.tweetUrl}
            target="_blank"
            className="underline"
            rel="noopener noreferrer"
          >
            &#64;{tweet.username}
          </a>
        </div>
      </div>
      <div className="flex">
        <div>
          <textarea
            id="text"
            name="text"
            ref={textareaRef}
            cols={100}
            defaultValue={tweet.improvedText}
            onChange={(e) => {
              setText(e.target.value);
              changeTextarea();
            }}
          ></textarea>
          {tweet.media?.map((m) => (
            <img
              key={m.url}
              src={m.url}
              className="max-w-[50%]"
              alt="tweet image"
            />
          ))}
        </div>
        <div className="flex space-x-4">
          <button
            type="submit"
            className="border-2 p-2 border-red-600 border-solid h-10"
            onClick={() => onSubmit(false)}
          >
            Tweet
          </button>
          <button
            type="submit"
            className="border-2 p-2 border-black-600 border-solid h-10"
            onClick={() => onSubmit(true)}
          >
            Promote
          </button>
          {postResult && <div>Result: {postResult}</div>}
        </div>
      </div>
    </>
  );
}

const Tweeter = () => {
  const [tweets, setTweets] = useState([]);
  useEffect(() => {
    (async () => {
      setTweets(await fetchTweets());
    })();
  }, []);
  console.log("tweets", tweets);
  /*
   */
  return (
    <>
      {tweets.map((t) => (
        <div key={t.tweetId} className="flex">
          <div className="inline m-4 border-2 p-2 border-black-600 border-solid">
            <TweeterForm tweet={t} />
          </div>
        </div>
      ))}
    </>
  );
};

export default Tweeter;
